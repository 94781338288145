// Core
import { StaticQuery } from "gatsby"
import React, { useRef, useEffect, useCallback } from "react"

// InView detector
import { InView } from "react-intersection-observer"

import { isMobile } from "react-device-detect"

//SVG
import Arrow from "./../../../static/images/svg/next-arrow.inline.svg"
// import Circle from "./../../../static/images/svg/circle.inline.svg"
import Circle from "./../../../static/images/svg/button-circle2.inline.svg"
// import Twitter from "./../../../static/images/svg/twitter.inline.svg"
import LinkedIn from "./../../../static/images/svg/linkedin.inline.svg"
// import Instagram from "./../../../static/images/svg/instagram.inline.svg"
// import Medium from "./../../../static/images/svg/medium.inline.svg"

// Styles
import styles from "./contact.module.scss"

const Contact = props => {
  const { open } = props,
    { setContactOpen } = props

  const modalRef = useRef(),
    btnRef = useRef()

  const close = useCallback(
    e => {
      e.preventDefault()
      if (open === true) {
        setContactOpen(false)
      } else {
        modalRef.current.setAttribute("data-contact-open", false)
      }

      if (isMobile) {
        document.querySelector("body").style.overflow = "auto"
      }
    },
    [open, setContactOpen]
  )

  const stopProps = useCallback(
    e => {
      e.stopPropagation()
      const { current } = btnRef

      current.addEventListener("click", close)
    },
    [close]
  )

  useEffect(() => {
    const { current } = modalRef,
      inner = current.querySelector(".text"),
      buttonRef = btnRef.current

    current.addEventListener("click", close)
    buttonRef.addEventListener("click", close)
    inner.addEventListener("click", stopProps)

    return () => {
      inner.removeEventListener("click", stopProps)
      current.removeEventListener("click", close)
      buttonRef.removeEventListener("click", close)
    }
  }, [open, close, stopProps])

  return (
    <StaticQuery
      query={graphql`
        query ContactQuery {
          allContentfulContactModal {
            edges {
              node {
                heading
                contactEmailAddress
                mediaEmailAddress
                address
                instagramUsername
                linkedInUrl
                mediumUrl
                twitterUsername
                contentful_id
              }
            }
          }
        }
      `}
      render={data => (
        <div
          data-contact-open={open}
          ref={modalRef}
          className={`${styles.contact} contactModal`}
        >
          <div className={`${styles.contactInner} contactinner`}>
            <div className="container">
              <InView triggerOnce={true} threshold={0.2}>
                {({ inView, ref, entry }) => (
                  <div className="cols" ref={ref} inview={inView.toString()}>
                    <div
                      className={`col m12 t7 ${styles.text} text`}
                      data-scroll
                    >
                      <div className={`${styles.textInner} textInner`}>
                        <button
                          className={styles.closebutton}
                          onClick={close}
                          ref={btnRef}
                          aria-label="Close Contact Modal"
                        >
                          <Circle />
                          <span></span>
                          <span></span>
                        </button>
                        <h3 className={`h1 subheading`}>
                          {data.allContentfulContactModal.edges[0].node.heading}
                        </h3>
                        <h2 className={`h3`}>
                          <a
                            href={`mailto: ${data.allContentfulContactModal.edges[0].node.contactEmailAddress}`}
                          >
                            {
                              data.allContentfulContactModal.edges[0].node
                                .contactEmailAddress
                            }{" "}
                            <span className={styles.arrowWrap}>
                              <span className={styles.arrow}>
                                <Arrow />
                              </span>
                              <span className={styles.arrow}>
                                <Arrow />
                              </span>
                            </span>
                          </a>
                        </h2>

                        <div className={styles.mediaEmail}>
                          <h3 className={`h1 subheading`}>
                            For Media inquiries contact
                          </h3>
                          <h2 className={`h3`}>
                            <a
                              href={`mailto: ${data.allContentfulContactModal.edges[0].node.mediaEmailAddress}`}
                            >
                              {
                                data.allContentfulContactModal.edges[0].node
                                  .mediaEmailAddress
                              }{" "}
                              <span className={styles.arrowWrap}>
                                <span className={styles.arrow}>
                                  <Arrow />
                                </span>
                                <span className={styles.arrow}>
                                  <Arrow />
                                </span>
                              </span>
                            </a>
                          </h2>
                        </div>

                        <p className={`copy copy--s ${styles.address}`}>
                          {data.allContentfulContactModal.edges[0].node.address}
                        </p>
                        <div className={styles.socialMedia}>
                          <ul className={styles.socialMediaList}>
                            {/*  <li>
                              <a
                                href={`https://instagram.com/${data.allContentfulContactModal.edges[0].node.instagramUsername}`}
                                target="_blank"
                                rel="noreferrer"
                                aria-label={`Aegis Labs' Instagram`}
                              >
                                <Instagram />
                              </a>
                            </li>
                            <li>
                              <a
                                href={`https://twitter.com/${data.allContentfulContactModal.edges[0].node.twitterUsername}`}
                                target="_blank"
                                rel="noreferrer"
                                aria-label={`Aegis Labs' Twitter`}
                              >
                                <Twitter />
                              </a>
                          </li>*/}
                            <li>
                              <a
                                href={`${data.allContentfulContactModal.edges[0].node.linkedInUrl}`}
                                target="_blank"
                                rel="noreferrer"
                                aria-label={`Aegis Labs' LinkedIn`}
                              >
                                <LinkedIn />
                              </a>
                            </li>
                            {/* <li>
                              <a
                                href={`${data.allContentfulContactModal.edges[0].node.mediumUrl}`}
                                target="_blank"
                                rel="noreferrer"
                                aria-label={`Aegis Labs' Medium`}
                              >
                                <Medium />
                              </a>
                            </li>*/}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </InView>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default Contact
